import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { Section } from "@/types/graphql";
import { RenderSectionLinks } from "@components/RenderSectionLinks";
import { getNode } from "@/utils";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { seoAtom, heroAtom } from "@/store";
import { Helmet } from "react-helmet";
import { useHydrateAtoms } from "jotai/utils";

type SectionsPageProps = {
  data?: {
    allSanityEp3Document?: {
      edges: { node: Section }[];
    };
  };
};

const SectionsPage = ({ data }: SectionsPageProps) => {
  const sectionNodes = data?.allSanityEp3Document?.edges.map(getNode) || [];
  const seo = { title: "EP3 Sections" };
  useHydrateAtoms([
    [seoAtom, seo],
    [heroAtom, undefined],
  ]);
  return (
    <>
      <Helmet>
        <title> Sections | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <BreadCrumbs paths={[{ url: "/", label: "Home" }]} title="EP3 Sections" />
            <Title>EP3 Sections</Title>
          </Header>
          <div className="flex flex-col py-12">
            <RenderSectionLinks sections={sectionNodes} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    allSanityEp3Document(sort: { fields: seo___title, order: ASC }) {
      edges {
        node {
          id
          heading
          slug {
            current
          }
          seo {
            title
            description
          }
          publicationStatus
        }
      }
    }
  }
`;

export default SectionsPage;
